"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.client = void 0;
const apiClient_1 = require("@cardlesshq/web-api-client/src/apiClient");
const authClient_1 = require("@cardlesshq/web-api-client/src/authClient");
const sdk_1 = require("./sdk");
const [username, password] = process.env.CONF_API_BASIC_AUTH.split(':');
const authClient = new authClient_1.AuthClient({
    baseUrl: process.env.CONF_API_AUTH_BASE_URL,
    auth: {
        username,
        password,
    },
});
const apiClient = new apiClient_1.ApiClient({
    baseUrl: process.env.CONF_API_BASE_URL,
});
exports.client = new sdk_1.SDKClient({
    authClient,
    apiClient,
});
exports.client.acceptSDKData();
// so we can test static methods
window.client = exports.client;
